@use "/src/index";

.company {
  .layout {
    width: 100%;
    max-width: 1260px;
    padding-left: 36px;
    padding-right: 36px;
    margin: auto;

    &-mobile {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .elementor-container {
    position: relative;
    width: 100%;
    height: 100vh;

    overflow: hidden;

    background: index.$black;

    &-mobile {
      height: 100vh;
    }

    .img-container {
      position: absolute;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.35;
      }
    }

    .elementor-title-wrapper {
      position: absolute;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 100%;
      height: 100%;

      color: index.$white;

      h2 {
        text-align: center;
      }
    }
  }

  .company-history-container {
    background: linear-gradient(270deg, #76d446, #3d6e24);
    padding: 76px 0;

    .layout {
      .slick-track {
        display: flex;
      }

      .slick-track .slick-slide {
        display: flex;
        height: auto;
        align-items: center;
        justify-content: center;
      }

      .slider-wrapper {
        display: flex;
        align-items: center;
        width: 90%;
        margin: auto;

        &-mobile {
          width: 100%;
        }

        .slider-container {
          width: 80%;
          margin: auto;

          .slider-item {
            height: 100%;

            .content-wrapper {
              display: flex;
              height: 100%;

              &-mobile {
                flex-direction: column;
                justify-content: start;
              }

              &>div {
                width: 100%;
              }

              .year-container {
                display: flex;
                justify-content: center;
                align-items: center;

                &-mobile {
                  margin-bottom: 26px;
                }
              }

              .history-container {
                position: relative;

                .history-month-line {
                  position: absolute;
                  top: 4%;
                  left: 20px;
                  height: 92%;
                  border-left: 1px solid index.$black;
                  z-index: -1;

                  &-mobile {
                    left: 13px;
                  }
                }

                .history-item-wrapper {
                  margin: auto;

                  .history-item {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: 16px;

                    &-mobile {
                      gap: 8px;
                    }

                    &+div {
                      margin-top: 40px;
                    }

                    .history-month {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 40px;
                      height: 40px;
                      background: #5ca436;

                      border: 1px solid index.$black;
                      border-radius: 100%;

                      &-mobile {
                        width: 27px;
                        height: 27px;
                      }
                    }

                    .history-text {
                      width: calc(100% - 56px);
                    }
                  }
                }
              }
            }
          }
        }
      }

      .slider-arrow-icon {
        cursor: pointer;

        &-mobile {
          width: 27px;
          height: 27px;
        }

        &.prev {
          transform: rotate(180deg);
        }
      }
    }
  }

  .discription-container {
    display: flex;
    justify-content: center;
    padding: 2.5em 0;

    .discription-wrapper {
      width: 90%;
      display: flex;

      .discription-left {
        width: 40%;

        .discription-heading {
          margin: 0;
        }

        .discription-heading-2 {
          margin: 0.6em 0;
        }

        .logo {
          width: 250px;
          height: auto;
          margin-bottom: 2.5em;
        }

        .about-btn {
          width: fit-content;
          background: #9fd080;
          color: white;
          font-size: 1.7em;
          font-weight: bold;
          padding: 0.5em;
          cursor: pointer;
          text-decoration: none;
        }
      }

      .discription-right {
        width: 60%;

        .bold {
          font-size: 1.2em;
          font-weight: bold;

          &:first-of-type {
            margin-top: 0;
          }
        }
      }
    }
  }

  .solutions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: index.$black;

    color: index.$white;

    padding: 186px 0 264px 0;

    &-mobile {
      padding: 50px 0 50px 0;
    }

    .layout {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .solutions-subtitle {
        margin-bottom: 12px;
      }

      .solutions-title {
        margin-bottom: 106px;

        &-mobile {
          margin-bottom: 32px;
        }
      }

      .solution-item-wrapper {
        display: flex;
        gap: 24px;

        &-mobile {
          flex-direction: column;
          gap: 32px;
        }

        .solution-item {
          width: 100%;
          background: index.$gray400;

          border-top: 6px solid index.$brand100;
          padding-top: 100px;
          padding-bottom: 100px;

          &-mobile {
            padding-top: 34px;
            padding-bottom: 40px;
          }

          .text-wrapper {
            width: 90%;
            margin: auto;

            .solution-item-number {
              display: none;
              color: index.$brand100;
              margin-bottom: 6px;

              &.display {
                display: block;
              }
            }

            h4 {
              margin-bottom: 30px;

              &.mobile {
                margin-bottom: 12px;
              }
            }
          }
        }
      }
    }
  }

  .solutions-container-2 {
    width: 100%;
    background: index.$black;
    color: index.$white;

    .solutions-below-overlay {
      width: 100%;

      padding: 158px 0 156px 0;

      background: linear-gradient(180deg,
          rgba(127, 252, 59, 0.16) 0%,
          rgba(0, 0, 0, 0.2) 87%);

      color: index.$white;

      &-mobile {
        padding: 50px 0;
      }

      .layout {
        margin: auto;

        .title {
          margin-bottom: 90px;
          text-align: center;

          &-mobile {
            margin-bottom: 32px;
          }
        }

        .solutions-item-wrapper {
          display: flex;
          gap: 24px;

          &-mobile {
            flex-direction: column;
            gap: 20px;
          }

          .solutions-item {
            width: 100%;
            height: 100%;
            min-height: 250px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
            aspect-ratio: 1.54;

            &:first-child {
              background-image: url("/assets/solution-image-1.png");
            }

            &:nth-child(2) {
              background-image: url("/assets/solution-image-2.png");
            }

            .image-overlay {
              display: flex;
              align-items: end;

              width: 100%;
              height: 100%;
              min-height: 250px;
              background: linear-gradient(0deg, index.$black, transparent);

              padding: 80px 60px;

              &-mobile {
                padding: 32px 16px;
              }

              .content {
                height: 100%;

                h3 {
                  margin-bottom: 24px;
                }

                p {
                  margin-bottom: 32px;
                }
              }

              .content-mobile {

                h3 {
                  margin-bottom: 8px;
                }

                p {
                  margin-bottom: 16px;
                }
              }
            }
          }
        }
      }
    }
  }

  .solutions-modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;

    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(4px);
    z-index: 5;

    display: none;

    &.modal-1,
    &.modal-2 {
      display: flex;
    }

    &.modal-1 .solutions-modal-title-container {
      background-image: url("/assets/solution-image-1.png");
    }

    &.modal-2 .solutions-modal-title-container {
      background-image: url("/assets/solution-image-2.png");
    }

    .solutions-modal-container {
      max-width: 900px;
      width: 90%;
      background: index.$white;

      border-radius: 4px;
      overflow: hidden;

      .solutions-modal-title-container {
        width: 100%;

        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;

        .solutions-modal-title-wrapper {
          background: rgba(0, 0, 0, 0.6);
          color: index.$white;
          padding: 50px 40px;

          &-mobile {
            padding: 0 24px;
            height: 90px;
            display: flex;
            align-items: center;
          }
        }
      }

      .text-wrapper {
        padding: 62px 92px;
        white-space: pre-wrap;

        &-mobile {
          padding: 24px;
        }
      }
    }
  }

  .partners-container {
    background: index.$white;

    .layout {
      max-width: 1260px;
      padding: 100px 36px 100px 36px;

      &-mobile {
        padding: 50px 16px;
      }

      .partners-scroll-container {
        position: relative;
        width: 100%;
        overflow: hidden;
        z-index: 1;
        margin: 0;
        padding: 0;
      }

      .partners-scroll-wrapper {
        overflow: hidden;
        height: 100%;
        white-space: nowrap;
        animation: scrollPartners 40s infinite linear;
        margin: 0;
        font-size: 0;
        display: flex;
        justify-content: space-between;
        width: fit-content;

        &-mobile {
          animation: scrollPartnersMobile 40s infinite linear;
        }
      }

      @keyframes scrollPartners {
        from {
          transform: translateX(20%);
        }

        to {
          transform: translateX(-100%);
        }
      }


      @keyframes scrollPartnersMobile {
        from {
          transform: translateX(10%);
        }

        to {
          transform: translateX(-100%);
        }
      }

      .partners-slider-item {
        width: 220px;
        min-height: 200px;

        .partners-image-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 100%;
          min-height: 200px;
          height: 100%;

          .partners-slider-image {
            width: 100%;
            height: auto;
            padding: 0 40px;
            margin-top: auto;
            margin-bottom: auto;
          }
        }
      }
    }
  }
}