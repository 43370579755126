// Typography

.font-display-lg {
  font-size: 60px;
  line-height: 72px;
  font-weight: 700;
}

.font-display-md {
  font-size: 48px;
  line-height: 60px;
  font-weight: 700;
}

.font-display-sm {
  font-size: 30px;
  line-height: 38px;
  font-weight: 700;
}

.font-display-xs {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
}

.font-text-lg {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
}

.font-text-md {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.font-text-sm {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
.font-text-xs {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}
