@use "/src/index";

.header-layout {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  background: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 74px;
  z-index: 10;

  transition: right 0.3s;

  &.isOpen {
    right: 300px;
  }
}

.header-container {
  width: 100%;
  max-width: 1260px;

  padding-left: 36px;
  padding-right: 36px;

  &-mobile {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
}

.header-logo {
  display: flex;
  align-items: center;

  .link {
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.6;
    }

    img {
      height: 32px;
    }
  }
}

.header-pagelist {
  display: flex;

  .link {
    display: flex;
    align-items: center;
    padding: 0 15px;

    &>span {
      position: relative;
      color: white;

      &::after {
        position: absolute;
        bottom: -30%;
        left: 0;
        content: "";
        width: 100%;
        height: 2px;
        background-color: index.$brand100;
        visibility: hidden;
        transform: scaleX(0);
        transform-origin: bottom left;
        transition: all 0.25s ease-out;
      }
    }

    &:hover {
      &>span {
        color: index.$brand100;

        &::after {
          visibility: visible;
          transform: scaleX(1);
        }
      }
    }
  }
}

.menu-button {
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: color 200ms;

  &:hover {
    color: #aaaaaa;
  }
}

.contact-button {
  border: 1px solid index.$white;
  color: index.$white;
  padding: 7px 20px;
  background: transparent;
  text-decoration: none;

  transition: all 0.3s;

  cursor: pointer;

  &:hover {
    border: 1px solid index.$brand200;
    color: index.$brand200;
  }
}