@use "/src/index";

.home {
  .img-responsive {
    position: relative;
    width: 100%;
    height: 900px;
    overflow: hidden;
  }

  .img-responsive-mobile {
    position: relative;
    width: 100%;
    height: 530px;
    overflow: hidden;
  }

  .img-container {
    position: absolute;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .background-overlay {
    position: absolute;
    top: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.65) 0%,
      rgba(0, 0, 0, 0.65) 90%,
      rgba(0, 0, 0, 1) 100%
    );
  }

  .home-content {
    width: 80%;
    max-width: 1260px;

    padding-left: 36px;
    padding-right: 36px;
    color: index.$white;
    display: flex;

    justify-content: space-between;
    align-items: center;
  }

  .home-content-mobile {
    width: 100%;
    color: index.$white;

    align-items: center;
    padding: 0 16px;
  }

  .home-content-left {
    display: flex;
    flex-direction: column;
    gap: 70px;

    align-items: start;

    .font-display-xs {
      margin-bottom: 12px;
    }
  }

  .home-content-left-mobile {
    display: flex;
    flex-direction: column;
    gap: 70px;

    text-align: center;

    .font-text-sm {
      margin-bottom: 4px;
    }

    .font-display-sm {
      margin-bottom: 62px;
    }
  }

  .arrow-button {
    display: flex;
    align-items: center;
    gap: 5px;

    border: 1px solid index.$white;
    padding: 7px 20px;

    background: transparent;
    color: index.$white;

    transition: all 0.3s;
    cursor: pointer;

    .arrow-icon {
      fill: index.$white;
      transition: all 0.3s;
    }

    &:hover {
      border: 1px solid index.$brand200;
      color: index.$brand200;

      .arrow-icon {
        fill: index.$brand200;
      }
    }
  }

  .arrow-button-mobile {
    width: 100%;
    justify-content: center;
  }

  .home-content-right {
    display: flex;
    flex-direction: column;
    gap: 78px;

    text-align: center;

    .font-display-xs {
      margin-bottom: 20px;
    }
  }

  .home-content-right-mobile {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 36px;

    display: flex;
    flex-direction: row;
    justify-content: space-around;

    text-align: center;

    .font-text-xs {
      margin-bottom: 4px;
    }
  }

  // Section 2

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    padding-top: 125px;
    padding-bottom: 228px;

    background-color: index.$black;
    color: index.$white;

    &-mobile {
      padding-left: 16px;
      padding-right: 16px;

      padding-top: 0px;
      padding-bottom: 76px;
    }
  }

  .content-text-title {
    margin-bottom: 24px;

    text-align: center;
  }

  .content-text-title-mobile {
    margin-bottom: 16px;
  }

  .content-text-description {
    width: 100%;
    margin-bottom: 40px;

    text-align: center;
    white-space: pre-wrap;
  }

  .content-text-description-mobile {
    margin-bottom: 16px;
  }

  .content-text-location {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    margin-bottom: 80px;

    color: index.$gray300;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      fill: index.$gray300;
    }
  }

  .content-text-location-mobile {
    display: flex;

    margin-bottom: 30px;

    color: index.$gray300;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 16px;
      height: 16px;
    }

    svg {
      fill: index.$gray300;
    }
  }

  .content-img-wrapper {
    width: 80%;
    max-width: 1260px;

    padding-left: 36px;
    padding-right: 36px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .content-img-wrapper-mobile {
    width: 100%;
  }

  .content-img-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 30%;
    aspect-ratio: 1;
    white-space: pre-wrap;
    text-align: center;

    border-radius: 50%;
    background: linear-gradient(180deg, #7ffc3b 0%, #1a4502 100%);
    color: index.$black;

    img {
      width: 68px;
      height: 68px;

      margin-bottom: 18px;
    }
  }

  .content-img-item-mobile {
    width: 100%;
    margin-bottom: 10px;
    padding: 18px 16px;

    display: flex;
    justify-content: space-between;

    border-radius: 4px;
    background: linear-gradient(180deg, #7ffc3b 0%, #1a4502 100%);
    color: index.$black;

    img {
      width: 24px;
      height: 24px;
    }
  }

  //section 3

  .content-below-container {
    width: 100%;

    color: index.$white;
    background: index.$black;

    white-space: pre-wrap;
  }

  .content-below-overlay {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    background: linear-gradient(
      180deg,
      rgba(127, 252, 59, 0.16) 0%,
      rgba(0, 0, 0, 0.2) 87%
    );

    &-mobile {
      padding: 0 16px;
    }
  }

  .content-below-title {
    text-align: center;
    padding-top: 182px;
    padding-bottom: 74px;
  }

  .content-below-title-mobile {
    padding-top: 50px;
    padding-bottom: 27px;
  }

  .content-below {
    width: 80%;
    max-width: 1260px;

    padding-left: 36px;
    padding-right: 36px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-bottom: 170px;

    &:nth-child(odd) {
      flex-direction: row-reverse;
    }
  }

  .content-below-mobile {
    width: 100%;
    margin-bottom: 36px;
  }

  .content-below-img-container {
    width: 54%;
    aspect-ratio: 8/5;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content-below-img-container-mobile {
    width: 100%;
    aspect-ratio: 8/5;
    margin-bottom: 16px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content-below-text {
    width: 36%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 22px;
  }

  .content-below-text-mobile {
    width: 100%;
  }

  .content-below-text-number {
    color: index.$brand100;
  }

  .content-below-text-number-mobile {
    color: index.$brand100;
    margin-bottom: 4px;
  }

  .content-below-text-title-mobile {
    margin-bottom: 8px;
  }
}
