@use "/src/index";

.outline-button-container {
  background: transparent;
  padding: 8px 20px;

  color: index.$white;

  border: 1px solid index.$white;
  cursor: pointer;

  transition: color 0.2s;

  &-mobile {
    padding: 4px 16px;
  }

  &:hover {
    border: 1px solid index.$brand200;
    color: index.$brand200;
  }
}