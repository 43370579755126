@use "/src/index";

.footer-layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: index.$gray400;

  &-mobile {}
}

.footer-container {
  width: 100%;
  max-width: 1260px;

  padding-left: 36px;
  padding-right: 36px;

  &-mobile {
    padding-left: 16px;
    padding-right: 16px;

    margin-bottom: 16px;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding: 50px 0;

  &-mobile {
    flex-direction: column;
  }

  .footer-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 40%;

    &-mobile {
      justify-content: start;
      margin-bottom: 16px;
    }

    .footer-logo {
      width: auto;
      height: 100%;
      max-height: 40px;
    }
  }

  .footer-description-container {
    width: 60%;

    &-mobile {
      width: 100%;
    }

    .footer-description-title {
      color: index.$white;
      margin-bottom: 24px;
    }

    .footer-description {
      .description-item {
        display: flex;
        align-items: center;

        margin-bottom: 8px;

        .icon {
          margin-right: 6px;
        }

        p {
          color: index.$gray200;
        }

      }
    }

  }
}

.footer-copyright-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  color: index.$gray300;

  padding: 40px 0;

  border-top: 1px solid index.$gray300;

  p {
    text-align: center;
  }

}