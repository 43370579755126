@use "/src/index";

.news {
  position: relative;
  width: 100%;

  background-color: index.$black;
  color: index.$white;

  .background-overlay {
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;

    background: linear-gradient(180deg,
        rgba(127, 252, 59, 0.16) 0%,
        rgba(0, 0, 0, 0.2) 87%);
  }

  .news-content {
    position: relative;
    width: 80%;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 157px 0 175px 0;
    margin: 0 auto;

    z-index: 1;
  }

  .news-content-mobile {
    position: relative;
    width: 100%;

    padding: 100px 16px 50px 16px;
  }

  .content-title {
    margin-bottom: 24px;
  }

  .content-title-mobile {
    margin-bottom: 4px;
    white-space: pre-wrap;
  }

  .content-description {
    margin-bottom: 106px;

    text-align: center;
  }

  .content-description-mobile {
    margin-bottom: 50px;
  }

  .news-video {
    width: 100%;
    aspect-ratio: 16/9;
  }

  .news-image-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    margin-top: 170px;
  }

  .news-image-wrapper-mobile {
    margin-top: 50px;
  }

  .news-item {
    margin-bottom: 24px;
  }

  .news-img {
    width: 100%;
    aspect-ratio: 800/478;

    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      transition: all 0.2s;

      &.contain-fit {
        background-color: index.$white;
        object-fit: contain;
      }
    }

    &:hover {
      img {
        transform: scale(105%);
      }
    }
  }

  .news-description {
    margin-top: 30px;
  }

  .news-description-mobile {
    margin-top: 16px;
  }
}