@use "/src/index";

* {
  box-sizing: border-box;
}

html {
  background: index.$gray400;
}

.orange-1 {
  color: #21863a;
}

.green-2 {
  color: #98ca7c;
}

.main-wrapper {
  overflow-x: hidden;
}

.main-container {
  position: relative;
  right: 0px;
  transition: all 0.3s;

  &.isOpen {
    right: 300px;
  }
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.menu {
  position: fixed;
  top: 0;
  right: -300px;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 100%;
  background-color: white;
  transition: all 0.3s;
  z-index: 11;

  &.isOpen {
    right: 0px;
  }

  .link {
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.035);
    cursor: pointer;

    &:hover {
      color: index.$brand200;
    }
  }
}

.isMobile {
  .content-divider {
    flex-direction: column;
  }

  .content-divider-content {
    flex-direction: column;

    &>div {
      margin-right: 0;
    }

    .news-link {
      margin-bottom: 32px;
    }
  }

  .content-below-container {
    flex-direction: column;
  }

  .background-overlay-content {
    flex-direction: column;

    &>div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;
    }
  }

  .content-divider-right {
    margin: 24px 0 0 0;
  }

  .elementor-container {
    width: 100%;
    height: 60vh;

    .elementor-label {
      display: none;
    }
  }

  .grid-container {
    .grid-row {
      display: block;

      .grid-item {
        width: 100%;

        .item-wrapper {
          padding: 2em;
        }
      }
    }
  }

  .discription-wrapper {
    display: block;

    .discription-left {
      width: 100%;
    }

    .discription-right {
      width: 100%;
      margin-top: 1.5em;
    }
  }
}