@use "/src/index";

.product {
  background-color: index.$black;

  .img-responsive,
  .img-responsive-mobile {
    position: relative;
    width: 100%;
    height: 120vh;
    overflow: hidden;
  }

  .img-responsive-mobile {
    height: 60vh;
  }

  .img-container {
    position: absolute;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .background-overlay,
  .background-overlay-mobile {
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.65) 0%,
      rgba(0, 0, 0, 0.65) 90%,
      rgba(0, 0, 0, 1) 100%
    );
  }

  .background-overlay-mobile {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.65) 0%,
      rgba(0, 0, 0, 0.65) 40%,
      rgba(0, 0, 0, 1) 100%
    );
    padding-left: 16px;
    padding-right: 16px;
  }

  .background-overlay-content {
    width: 80%;
    max-width: 1260px;

    padding-left: 36px;
    padding-right: 36px;
    margin: 0 auto;

    color: index.$white;
  }

  .background-overlay-content-mobile {
    width: 100%;

    margin: 0 auto;

    color: index.$white;
  }

  .background-overlay-content-top {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 70px;

    align-items: start;

    margin-top: 200px;
    margin-bottom: 170px;

    .product-title {
      margin-bottom: 12px;
    }
  }

  .background-overlay-content-top-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;

    margin-top: 150px;
    margin-bottom: 165px;

    .product-title-mobile {
      margin-bottom: 4px;
      text-align: center;
    }

    .product-description-mobile {
      margin-bottom: 34px;
      text-align: center;
    }
  }

  .arrow-button,
  .arrow-button-mobile {
    display: flex;
    align-items: center;
    gap: 5px;

    border: 1px solid index.$white;
    padding: 7px 20px;

    background: transparent;
    color: index.$white;

    transition: all 0.3s;
    cursor: pointer;

    .arrow-icon {
      fill: index.$white;
      transition: all 0.3s;
    }

    &:hover {
      border: 1px solid index.$brand200;
      color: index.$brand200;

      .arrow-icon {
        fill: index.$brand200;
      }
    }
  }

  .arrow-button-mobile {
    width: 100%;
    justify-content: center;

    .arrow-icon {
      width: 15px;
    }
  }

  .tabs-container,
  .tabs-container-mobile {
    width: 100%;

    margin-top: -30vh;
    padding-bottom: 20vh;

    z-index: 5;
  }

  .tabs-container-mobile {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: -10vh;
  }

  .tabs {
    width: 80%;
    max-width: 1260px;

    padding-left: 36px;
    padding-right: 36px;

    margin: 0 auto;
    display: flex;
    gap: 36px;
    justify-content: flex-end;

    margin-bottom: 36px;
  }

  .tabs-mobile {
    width: 100%;
    margin: 0 auto;
    display: flex;
    gap: 16px;

    justify-content: center;

    margin-bottom: 20px;
  }

  .tab-button {
    position: relative;

    color: index.$gray200;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.3s;

    &::before {
      position: absolute;
      bottom: -30%;
      left: 0;
      content: "";
      width: 100%;
      height: 2px;
      background-color: index.$gray200;
    }

    &::after {
      position: absolute;
      bottom: -30%;
      left: 0;
      content: "";
      width: 100%;
      height: 2px;
      background-color: index.$brand200;
      visibility: hidden;
      transform: scaleX(0);
      transform-origin: bottom left;
      transition: all 0.25s ease-out;
    }

    &:hover {
      color: index.$brand200;
      transition: all 0.3s;

      &::after {
        visibility: visible;
        transform: scaleX(1);
      }
    }
  }

  .tab-button.active {
    color: index.$brand200;

    &::before {
      background-color: index.$brand200;
    }
  }

  .tabs-images-grid {
    width: 80%;
    max-width: 1260px;
    padding-left: 36px;
    padding-right: 36px;

    margin: 0 auto;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 23px;
  }

  .tabs-images-grid-mobile {
    width: 100%;
  }

  .tab-image {
    width: 100%;
    height: 100%;
    aspect-ratio: 7/4;

    object-fit: cover;
  }

  .tab-image-mobile {
    width: 100%;
    margin-bottom: 16px;
  }

  .preparing-message {
    padding: 100px 0;

    color: index.$white;
    text-align: center;
  }
}
