@use "/src/index";

.contact {
  width: 100%;
  background-color: index.$black;
  color: index.$white;

  .contact-info-wrapper {
    width: 50%;
  }

  .contact-content {
    position: relative;

    width: 80%;
    max-width: 1260px;
    padding: 198px 36px 100px 36px;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
  }

  .contact-content-mobile {
    width: 100%;
    padding-top: 82px;
    padding-bottom: 50px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .info-window {
    color: index.$black;
  }

  .gm-style-iw-chr button {
    width: 20px !important;
    height: 20px !important;
  }

  .gm-style-iw-chr button span {
    margin: 0px !important;
    width: 15px !important;
    height: 15px !important;
  }

  .contact-info-text {
    margin-bottom: 40px;
  }

  .contact-title {
    margin-bottom: 40px;
  }

  .contact-title-mobile {
    margin-bottom: 16px;
  }

  .contact-info-item {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 12px;

    .icon {
      width: 20px;
      height: 20px;
      fill: index.$brand200;

      svg {
        width: 20px;
        height: 20px;
        fill: index.$brand200;
      }
    }
  }

  .contact-info-item-mobile {
    display: flex;
    align-items: center;
    gap: 2px;
    margin-bottom: 12px;

    .icon {
      width: 16px;
      height: 16px;
      fill: index.$brand200;

      svg {
        width: 16px;
        height: 16px;
        fill: index.$brand200;
      }
    }
  }

  .contact-form,
  .contact-form-mobile {
    width: 40%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 4px;
    color: index.$white;

    border: 2px solid index.$gray200;

    form {
      width: 85%;
      padding: 46px 0;
      min-height: 200px;
    }

    input,
    textarea {
      width: 100%;
      padding: 14px 20px;
      margin: 0 auto;
      margin-bottom: 12px;

      border-radius: 4px;
      border: 1px solid index.$gray200;
      outline: none;

      background-color: transparent;
      color: index.$white;

      &:focus {
        border: 1px solid index.$brand200;
      }
    }

    textarea {
      resize: none;
    }
  }

  .contact-form-mobile {
    width: 100%;

    margin-top: 38px;
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 6px;
    margin-bottom: 24px;
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]+span {
    position: relative;
    width: 20px;
    height: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: index.$white;
    border: 1px solid index.$gray200;
    border-radius: 50%;
    transition: all 0.3s ease;

    &:hover {
      border-color: index.$brand200;
    }
  }

  input[type="checkbox"]:checked+span {
    background-color: index.$brand200;
    border-color: index.$brand200;

    &::before {
      content: "✓";
      position: absolute;
      color: index.$white;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .checkbox-text {
    color: index.$gray200;
  }

  .submit-button {
    display: flex;
    align-items: center;
    gap: 5px;

    border: 1px solid index.$gray200;
    padding: 7px 20px;

    background: transparent;
    color: index.$gray200;

    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      border: 1px solid index.$brand200;
      color: index.$brand200;
    }

    &:disabled {
      cursor: not-allowed;
      background: index.$gray200;
      color: index.$gray300;
      border: 1px solid index.$gray300;
      opacity: 0.6;
    }
  }

  .content-below-container {
    position: relative;
  }

  .background-overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    background: linear-gradient(180deg,
        rgba(127, 252, 59, 0.16) 0%,
        rgba(0, 0, 0, 0.2) 87%);

    z-index: 1;
  }

  .img-content {
    position: relative;
    z-index: 2;

    &-mobile {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .img-title {
    margin-top: 140px;
    margin-bottom: 100px;
    text-align: center;
  }

  .img-title-mobile {
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
  }

  .img-container {
    width: 80%;
    max-width: 1260px;
    margin: 0 auto;
    margin-bottom: 140px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .img-container-mobile {
    width: 100%;
    margin: 0 auto;

    margin-bottom: 50px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      margin-bottom: 10px;
    }
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100vw;
    height: 100vh;

    z-index: 999;
  }

  .modal-content {
    background-color: index.$white;
    color: index.$black;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 300px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
}